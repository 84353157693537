* {
  box-sizing: border-box; 
  /* margin : 0px; */
  /* margin : auto; */
  /* padding : 0px; */
}

body {
  box-sizing: inherit;
  overflow-y: hidden;
}

.App {
  background-color: #282c34;
  background-color: #6e6b44;
  background-color: #525032;
  background-color: #57613c;
  background-color: #2c3726;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  color: #4d6d2a;
  color: #9aa8a3;
  color: #6e6b44;
  font-size: calc(10px + 2vmin);
  text-align: center;
  box-sizing: inherit;
}

main {
  /* min-height: inherit; */
  align-items: inherit;
  display: inherit;
  box-sizing: inherit;
}

.FlexColumn {
  /* flex: 50%; */
  /* width: 50vw; */
  /* max-width: 50%; */
  /* height: 50vh; */
  width: 50%;
  box-sizing: inherit;
}

/* .g1 {
  align-items: right !important;
} */

.g2 {
  /* this toggle bumps the left pannel out of vertical alignment */
  /* height: 100vh; */
  box-sizing: inherit; 
}

/* stretch - eliminate the green region from above the display_panel */
.App-Home-detail_panel {
  padding: 20px 60px 60px;
}

.App-logo {
  /* pointer-events: none; */
  height: 10vmin;
  width: auto;
}

h1 {
  margin-top: 0;
  color: #61dafb;
  color: #d2db75;
}

/* .Name {
  color: #61dafb;
} */

.App-link {
  color: #61dafb;
  color: #d3ea0b;
  color: #6e6b44;
  color: #d2db75;
}

button {
  background-color: transparent;
  border-color: #6e6b44;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
}

.socials {
  padding-top: 0.5em;
  padding-left: 1em;
}

a {
  margin-right: 0.5em;
}

/*  */
/* Attempting to center the App-profile-photo allowing hidden overflow */
/*  */

.App-profile-photo {
  width: 100%;
  height: 100%;
  /* height: 100vh; */ 
  object-fit: cover; /* similar: cover, contain, none // dissimilar: fill, unset, initial, scale-down  */
  /* object-position: 100%; */
}

@media (max-width: 1440px) {
  .App {
    justify-content: center;
  }
  .App-profile-photo {
    /* height: 100vh; */
    height: 100vmin;
  }
}

@media screen and (max-width: 1023px) {
  * {
    box-sizing : border-box; 
    /* margin : 0px; */
    /* margin : auto; */
    /* padding : 0px; */
  }
  /* body {
    overflow-y: auto;
  } */
  .App {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-content: unset;
  }
  main {
    /* min-height: inherit; */
    /* align-items: inherit; */
    display: inherit;
  }
  .FlexColumn {
    width: 100%;
    /* align-items: center; */
    /* justify-content: center; */
  }
  .g1 {
    grid-row: 1;
    height: 50vh;
  }
  /*  */
  /* Experimenting with g2 overflow & scrollbars */
  /*  */
  .g2 {
    grid-row: 2;
    /* height: 100vh;   */
    /* ^cont^ */
  }
  /* .cont {
    height: 100vh;
  } */
  h1, h2 {
    margin: 0px;
  }
  .App-profile-photo {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

/* todo - edge case for very low window height and very extended width */
