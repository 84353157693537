body {
    overflow-y: hidden;
    border: 0px;
    margin: 0px;
    padding: 0px;
    overscroll-behavior: none;
}

.App-resume_cont {
    /* iframe container pos is relative while the iframe pos is absolute */
    /* ref - https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing#:~:text=On%20the%20other%20hand%2C%20when%20using%20position%3A%20relative%20or%20position%3A%20absolute%2C%20use%20of%20box-sizing%3A%20content-box%20allows%20the%20positioning%20values%20to%20be%20relative%20to%20the%20content%2C%20and%20independent%20of%20changes%20to%20border%20and%20padding%20sizes%2C%20which%20is%20sometimes%20desirable. */
    box-sizing: content-box;

    height: 100vh;
}

.iframe-container {
    position: relative;
    overflow-y: hidden;
    width: 100%;
    /* padding-top: 129.41%; */
    padding-top: 100%;
    padding-bottom: none;
    /* scroll-padding-bottom: 0; */
    /* -webkit-overflow-scrolling: touch !important; */
    margin-bottom: none;
    border: none;
    line-height: 100vh;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 75vh;
    border: none;
    opacity: 0.8;
    display: block;
    /* vertical-align: bottom; */
}

@media screen and (max-width: 1440px) {
    .iframe-container {
        overflow-y: scroll;
    }
}

@media screen and (max-width: 1023px) {
    .responsive-iframe {
        overflow-y: hidden;
        height: 30vh;
    }
}
