.App-Port-content {
    overflow-y: scroll;
    height: 100vh;
}

@media screen and (max-width: 1023px) {
    .App-Port-content {
        overflow-y: scroll;
        height: 50vh;
    }
}
