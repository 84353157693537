span {
    display: block;
}

.App-Project-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 10px;
    width: 80%;
    font-size: x-large;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.App-Project-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.App-Project-card_cover {
    height: 20vh; /* avoid image tiling */
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover; /* equivalent to 100% */
}

.App-Project-card_content {
    /* Take available height */
    flex: 1;
    padding: 2px 16px;
}

.App-Project-name {
    color: #61dafb;
    color: #f7630c;
}

.App-Project-concept {
    color: #d2db75;
}

.App-Project-stack {
    color: #9aa8a3;
}

@media screen and (max-width: 1440px) {
    .App-Project-card  {
        font-size: medium;
    }
}

@media screen and (max-width: 600px) {
    .App-Project-card_content a img {
        transform: scale(0.75);
    }
    .App-Project-card  {
        font-size: medium;
    }
    .App-Project-concept {
        font-size: small;
    }
    .App-Project-stack {
        font-size: x-small;
    }
}

@media screen and (max-width: 450px) {
    .cont {
        overflow-y: scroll;
        height: 30vh;
    }
    .App-Project-card_cover {
        height: 75px;
        /* background-size: contain; */
    }
}
