.App-About-content {
    box-sizing: inherit;
    padding: 30px; /* margin misaligns the scrollbar */
}

p {
    /* font-size: x-large; */
    /* font-size: 2.6vh; */
    font-size: 1.24vmax;
}

@media screen and (max-width: 1023px) {
    h6 {
        margin: 1.5em 0 1.5em 0;
    }
    .App-About-content {
        overflow-y: scroll;
        height: 50vh;
    }
}
